import React from 'react';
import './Clients.css';
import Blog from '../../components/Blog';

const Clients = props => {
  return (
    <div id='Clients'>
      <Blog page={'clients'}/>     
    </div>
  );
};

export default Clients;