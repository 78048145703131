import React from 'react';
import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const styles = (theme) => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
});

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h5',
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
    h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true },
    },
    p: { component: Typography, props: { paragraph: true } },
    a: { component: Link },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
  },
};

const post1 = `
## How I Came to This Work

#### I have always had an interest in vitality and aging well.  

In my youth, I spent time working in the nursing home where my mother also worked as a geriatric nurse. I was fascinated by the residents of the home—their stories, their photos from their days as stylish 1920s flappers, or their memories of the old country and of Ellis Island. Their full lives intrigued me, as did the fact that they were now using walkers or were bed- or wheelchair-bound. Even as a teenager, I couldn’t help but feel a sense of cognitive dissonance. I tried to make sense of their vibrant pasts, the quiet dignity of their now-restricted lives, and the surprising fact that some members of the nursing staff were of similar age. One nurse, in particular, was in her 80s. While she did spend a good deal of time vacationing on cruise ships, she also made it a point to be available to work, and frequently substituted for other nurses when they called in sick or had maternity leave. She was spry, walked fast, and had no trace of a bent or shrinking spine. Seeing her in contrast to the nursing home residents, I had to wonder, how was it that health and aging could look so different? By the time I left for college, it was clear to me that I wanted to figure out how I could be like that nurse on staff—cheerful, full of vigor, and clearly enjoying life for the long haul.
`;

const post2 = `
#### For nearly twenty years, I spent much of my time in dance training. 

One summer, when I was studying modern dance at the American Dance Festival in Durham, I was lucky enough to have a daily technique class with Betty Jones, a legendary dancer who had been an original member of the José Limón Dance Company. By the time our paths crossed, she was in her 70s, teaching her technique class in a unitard (not a muumuu!). Certainly, she was lithe and graceful, but beyond that, she could jump and dive to the floor! I had to hastily put on knee pads to do moves she executed with ease. Here was this petite woman who stood up tall, who embodied both grace and power, and whose presence filled the room. The combination left an indelible impression. I decided that if I was going to be like that in my 70s, I had better get to work.

For many years, my daily routine included hours of physical discipline, all of which I did in my pursuit to find more grace, fluidity, and ease throughout my body. I enjoyed physical training and so was quite willing to work hard, but it wasn’t until I explored gentler methods that I finally realized ease and fluidity didn’t simply come from enormous amounts of disciplined effort. I had to work smart; not just work hard. Small, gentle adjustments are akin to wiggling a key in a stuck lock. You make tiny shifts until you are in position for things to “click” into place. If you aren’t in the right place, you can use a lot of force and repetition, but the key still won’t turn. Likewise, without subtle refinements, many physical movements are simply an exercise in fighting yourself—leaving your joints inflamed, your muscles unduly strained, and you feeling out of sorts.
`;

const post3 = `
#### Amazed by the results of my explorations, I started to study different kinds of movement therapies in earnest, and then eventually developed my own techniques. 

I began to add these gentle methods to my adult dance classes, basically fusing dance technique with movement therapy, which is quite an uncommon approach. The gentle movement section quickly became the students’ favorite part of class. To my surprise, they loved my techniques not because it made their dancing better, but because the felt so relaxed and grounded. They regularly reported that working with me in this way offered them a way to feel good in their skin that they hitherto didn’t know existed. It was only when they allowed themselves to “work” on relaxing (an oxymoronic concept, to be sure, in our culture of busy-ness), that they realized they had been living in a low-level state of malaise. Now, they stood taller, looked younger, moved with more ease and vigor, and delightedly mentioned the disappearance of many long-standing aches and pains.
`;

const post4 = `
#### Fast forward twenty-five years.

By now I have worked with clients for over two decades, helping them to gently dis-armor themselves,  to remove braces they didn’t even know they had installed. In that time, the scope of my work has expanded to encompass problem-solving for professional musicians, actors, athletes, and those dealing with chronic pain and injuries. No matter with whom I am working, though, the heart of my work remains the same. By inviting the body to become more grounded, I gently guide people to a state of internal harmony—from which they can then more fully express and give voice to the richness and expansiveness of their individual souls.
`;

export default function AboutPosts() {
  return (
    <div>
        <ReactMarkdown options={options}>{post1}</ReactMarkdown>
        <ReactMarkdown options={options}>{post2}</ReactMarkdown>
        <ReactMarkdown options={options}>{post3}</ReactMarkdown>
        <ReactMarkdown options={options}>{post4}</ReactMarkdown>
    </div>
  );
  
}
