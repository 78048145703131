import React from 'react';
import './Home.css';
import Blog from '../../components/Blog';

const Home = props => {
  return (
    <div id='Home'>
      <Blog page={'home'}/>     
    </div>
  );
};

export default Home;