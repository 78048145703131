import React from 'react';
import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const styles = (theme) => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
});

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h5',
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
    h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true },
    },
    p: { component: Typography, props: { paragraph: true } },
    a: { component: Link },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
  },
};

const post1 = `
#### The movements are very relaxing, so does that mean they have the same kind of effect as a massage?

The movements are very relaxing, so like a massage, you leave a session with less tension in your muscles. But the gentle movements are also a means through which your body learns new neurological patterns, so when you next use your body, you are doing it in a more harmonious and less stressful manner. Since learning is involved, the effects are typically longer-lasting than that of massage.
`;

const post2 = `
#### I haven’t had an injury. Why is it important to develop new connections in the body? I thought the body did that automatically?

Your body and mind are extremely adaptable, and if you are not feeling harmonious in your body, you have “automatically” adapted to less than ideal situations, whether in your physical environment or your social one.

Consider this: One of the main factors driving the development of the brain has been the degree to one is a social creature. Mammals and birds have bigger brains than the more solitary reptiles; and the more social the primate, the bigger the brain. While you may think that the large human brain evolved to build stone tools, it really grew in size to manage relationships, raise offspring, and get the approval of others in your group because being alone on the ancient savanna would have surely meant a certain death.

Your modern self is still wired in this manner, and so you unconsciously pick up tension patters in the process of being a normal, approval-loving human being. Emulating the posture of a parent, learning to play a sport or musical instrument, or being the “good,” “smart,” or “cool” kid all come with certain patterns. Learning new connections won’t erase your identity in your group, nor your hard-won skills. In fact, quite the opposite happens. In the process of refining your patterns, you let go of what doesn’t work for you and instead cultivate what does, leaving you more skilled, feeling good in your skin, and a more relaxed, confident, version of yourself whatever the situation. While it is human to pick up tension patterns, it is just as human to shine as yourself.
`;

const post3 = `
#### Is chronic tension that big a deal?

Modern society sees chronic tension as a normal, and such is the power of culture that most people do not even question it. Most people also do not question what aging should look like: the gradual and mysterious onset of increased aches and pains and various illnesses. However the two are very much related.

When you have a part of your body that is tense, you develop a stress riser, that is to say, your tissue is non-homogenous in that area: part of it is under stress and part of it is not. What happens to stress risers when some kind of mechanical force is applied? If you repeatedly make a crease in a sheet of paper, you have just formed a stress riser in the tissue we call paper. If you were to then hold the paper by two corners and give a gentle tug, where would the paper tear? Predictably, it would tear along the crease. Similarly, when you have an area of chronic tension in your body, you have developed a stress riser: a weakened patch of tissue that can potentially ache or give way—often “out of the blue” and “without notice.”

As you probably know from experience, muscles that are chronically tense do not relax well: They are held tight. Muscles are like rubber bands in that they are made to both lengthen and contract readily. While you may not need to use the full range of your muscles to drive to the grocery store, your physiology evolved long ago when resources were relatively scarce. Muscular activity signals to the body where resources should go. It takes a lot of fuel to maintain tissues, so the regions where your muscles are inactive and tight are taken “off the grid.” They become less active metabolically; they burn fewer calories because blood flow, lymph flow, and neurological activity all diminish. Less blood flow is tantamount to saying the food trucks don’t visit that neighborhood. Less lymph flow means the garbage trucks don’t pick up there either, leaving cells steeping in an acidic environment of cellular waste. A sluggish neurological system is akin to having a very slow and intermittent internet connection in your body: messages about bodily coordination and sensation may not go through, nor do important messages about the state of health of those tissues. Consequently, you may not even be aware of the tightness that has developed over time, or you may chalk up gradual restrictions in mobility as the natural progress of “aging.” These physiological responses to chronic tension treat the affected tissues like neglected and under-serviced neighborhoods in an otherwise clean and vibrant city—leaving them the stress risers most vulnerable to disease and illness.
`;

const post4 = `
#### How can I tell if part of my body is offline?

A very good test of your general health is your ability to sit down and get up from the floor without the aid of your hands or knees. To do this, most of your muscles need to be participating. Tension in any part of you, particularly in your back or pelvis, will make the task considerably more awkward.
`;

const post5 = `
#### Do you work with people who suffer from chronic pain or injuries?

Absolutely. It is in these situations that gently working with the nervous system can offer considerable benefit. My work gently invites your body to disarm, to release the manifold layers of tension it has developed to brace yourself—whether from some past situation or from the current pain itself. Chronic pain and injuries are often multi-factorial in nature, and in my experience, addressing hidden tension patterns can open up a beautiful space of peaceful respite—a burgeoning place for something new to unfurl and develop.
`;

export default function AboutPosts() {
  return (
    <div>
        <ReactMarkdown options={options}>{post1}</ReactMarkdown>
        <ReactMarkdown options={options}>{post2}</ReactMarkdown>
        <ReactMarkdown options={options}>{post3}</ReactMarkdown>
        <ReactMarkdown options={options}>{post4}</ReactMarkdown>
        <ReactMarkdown options={options}>{post5}</ReactMarkdown>
    </div>
  );
  
}
