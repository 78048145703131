import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Sylvia from '../images/2020-Sylvia.jpg';

const useStyles = makeStyles((theme) => ({
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
}));

export default function Sidebar(props) {
  const classes = useStyles();
  const { testimonials, description, social, title } = props;

  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={0} className={classes.sidebarAboutBox}>
        <Typography variant="h6" style={{ fontWeight: 600 }} gutterBottom>
          {title}
        </Typography>
        <Typography style={{paddingBottom: '10px'}}>{description}</Typography>
        <img src={Sylvia} alt="Sylvia Lim"></img>
      </Paper>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 600 }} className={classes.sidebarSection}>
        Testimonials
      </Typography>
      {testimonials.map((archive) => (
        <Typography display="block" variant="body1" style={{paddingBottom: '16px'}} key={archive.title}>
          {archive.title}
        </Typography>
      ))}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 600 }} className={classes.sidebarSection}>
        Contact
      </Typography>
      <Typography display="block" variant="body2" style={{paddingBottom: '16px'}}>
       For an appointment, email khadro.sylvia@gmail.com.
      </Typography>
      <Typography display="block" variant="body2" style={{paddingBottom: '16px'}}>
       Virtual Sessions now available over Zoom.
      </Typography>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 600 }} className={classes.sidebarSection}>
        Studio Location
      </Typography>
      <Typography display="block" variant="body2" style={{paddingBottom: '16px'}}>
        Northgate Park area, Durham, NC 27704
      </Typography>
      {/* {social.map((network) => (
        <Link display="block" variant="body1" href="#" key={network}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item>
              <network.icon />
            </Grid>
            <Grid item>{network.name}</Grid>
          </Grid>
        </Link>
      ))} */}
    </Grid>
  );
}

Sidebar.propTypes = {
  testimonials: PropTypes.array,
  description: PropTypes.string,
  social: PropTypes.array,
  title: PropTypes.string,
};
