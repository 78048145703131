import React from 'react';
import './Faq.css';
import Blog from '../../components/Blog';

const FAQ = props => {
  return (
    <div id='FAQ'>
      <Blog page={'FAQ'}/>     
    </div>
  );
};

export default FAQ;