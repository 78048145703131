import React from 'react';
import './About.css';
import Blog from '../../components/Blog';

const About = props => {
  return (
    <div id='About'>
      <Blog page={'about'}/>     
    </div>
  );
};

export default About;