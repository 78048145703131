import React from 'react';
import { Home } from './views/Home';
import { About } from './views/About';
import { Faq } from './views/FAQ';
import { Clients } from './views/Client';
// import { NavBar } from './components/NavBar';
import { Route, Routes, Navigate } from 'react-router-dom';

export const Routing = () => {
  return (
    <div id='routes'>
      <Routes>
        <Route exact path="/home" element={<Home/>} />
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/faq" element={<Faq/>} />
        <Route exact path="/clients" element={<Clients/>} />
      </Routes>
    </div>
  );
};