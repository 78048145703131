import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import Main from './Main';
import About from './About/About';
import FAQ from './FAQ/FAQ';
import Clients from './Clients/Clients';
import Sidebar from './Sidebar';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}));

const sections = [
  { title: 'Home', url: '/home' },
  { title: 'Common Questions', url: '/faq' },
  { title: 'About', url: '/about' },
  { title: 'Client Resources', url: '/clients' }
];

const mainFeaturedPost = {
  title: 'Supple Body Smart Mind',
  description:
    "Where neuroscience and biomechanics meet. Gentle body techniques for helping you ground, de-stress, and enjoy your life.",
  image: 'http://supplebodysmartmind.com/dew-drop-3.jpg',
  imgText: 'main image description'
};

const sidebar = {
  title: 'Sylvia Lim, Ph.D.',
  description: 'Movement Therapist. Biomechanical Specialist.',
  testimonials: [
    { title: "'Sylvia is a natural teacher...her face radiates as she's teaching.'"},
    { title: "'As I was walking home afterward, I felt in charge of my space…You have really mastered a unique relaxation technique.'" },
    { title: "'After my back surgery, nothing has helped with the pain as much as this. My body thanks you.'" },
    { title: "'This is like going to your 'Happy Place,' only better!'" },
    { title: "'I slept better yesterday than in weeks, and my neck is feeling more limber! Thanks!'" },
    { title: "'In my aerobics class the next morning, I felt like a marionette whose strings got all loosened. Everything felt so bouncy!'" },
    { title: "'I went to the doctor's office for my yearly exam and found that I am a half inch taller now! I told them it was because of my sessions with you. I'm so excited! Most people my age are starting to lose height, not gain it.'" },
    { title: "'I'm taller now and have so much energy! I feel like I could go out and run a marathon after leaving your studio.'" },
    { title: "'I had no idea I could be so relaxed.'" },
    { title: "'I feel like I'm being re-formed from the inside out!'" },
    { title: "'Working with Sylvia put me in touch with a way of feeling that I had long forgotten – the feeling of safety of my fourth-grade self.'" }
  ]
};

/**
 * Main outer wrapper for the blog, and component for each section
 * @param {*} page - home, about, faq, or client. Controls the section of the blog that displays
 * @returns 
 */
export default function Blog({page}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header /* title="Supple Body, Smart Mind" */ sections={sections} />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={5} className={classes.mainGrid}>
            {page === 'home' ? <Main title="Home" /> : page === 'about' ? <About title="About" /> : 
            page === 'FAQ' ? <FAQ title="Common Questions" /> : <Clients title="Client Resources" />
            }           
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              testimonials={sidebar.testimonials}
            />
          </Grid>
        </main>
      </Container>
      <Footer title="Footer" description="Something here to give the footer a purpose!" />
    </React.Fragment>
  );
}
